#loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#loading > #spinner {
    width: 8rem;
    height: 8rem;
}